<template>

      <v-container fluid style="height: 80%;">

        <v-row align="center" justify="center" style="height: 100%;">
          <v-col cols="12" sm="6" md="4">
            <v-card class="elevation-12">
              <v-toolbar dark color="#f0e633">
                <v-toolbar-title class="black--text">Formulario de ingreso</v-toolbar-title>
                <v-spacer></v-spacer>
              </v-toolbar>
      
              <v-form @submit.prevent="login" id="login-form">
              <v-card-text>
                  <v-text-field id="email" prepend-icon="mdi-account" name="email" label="Correo electrónico" type="text" v-model="user.email"></v-text-field>
                  <v-text-field id="password" prepend-icon="mdi-lock" name="password" label="Contraseña" type="password" v-model="user.password"></v-text-field>
              </v-card-text>
              
              <v-card-actions>
                <v-row>
                    <v-col cols="12">
                        <v-btn dark block large color="black" form="login-form" type="submit"> <v-icon left>mdi-login</v-icon> Ingresar</v-btn>
                    </v-col>
                    <v-col cols="12">
                        <v-btn dark block large color="black" @click="modalSignup = true"><v-icon left>mdi-clipboard-account</v-icon> Crear cuenta</v-btn>
                    </v-col>
                    <v-col cols="12" class="text-center">
                        <a @click="resetModal = true" style="color: #3371e7; text-decoration: none;">¿Olvidaste tu contraseña?</a>
                    </v-col>
                </v-row>
              </v-card-actions>

              </v-form>

            </v-card>

              <div v-if="error">
                  <v-alert
                    v-model="alert"
                    dismissible
                    type="error"
                  >
                    {{ error.message }}
                  </v-alert>
              </div>

              <div v-if="success">
                  <v-alert
                    v-model="alertSuccess"
                    dismissible
                    type="success"
                  >
                    {{ success.message }}
                  </v-alert>
              </div>

          </v-col>
        </v-row>

    
        <v-dialog
        v-model="resetModal"
        max-width="500"
        scrollable
        >
        <v-card>
            <v-toolbar color="#f0e633">
            <v-btn icon @click="resetModal = false">
                <v-icon>mdi-arrow-left-circle</v-icon>
            </v-btn>
            <v-toolbar-title>Regresar</v-toolbar-title>
            </v-toolbar>
            <v-form
                ref="form"
                
            >
            <v-card-title 
                class="headline grey lighten-2"
                primary-title
                style="color: #4a4a4a;"
            >
            Recuperar contraseña
            </v-card-title>
            
            <v-card-text>
                <v-text-field 
                prepend-icon="mdi-account" 
                name="email" 
                label="Correo electrónico" 
                type="text" 
                v-model="emailReset"
                hint="Por favor ingrese la dirección de correo electrónico que utilizó en el registro para que podamos enviarle un enlace de restablecimiento de contraseña."
                persistent-hint>
                </v-text-field>
            </v-card-text>
            </v-form>
            
            <v-divider ma-0 style="margin-left:1px;"></v-divider>
            <v-card-actions style="padding: 15px;">
                <v-row wrap>                    
                    <v-btn dark block large color="#3371e7" @click="resetPassword">Enviar</v-btn>
                </v-row>
            </v-card-actions>
            
        </v-card>
        </v-dialog>

        <v-dialog
        v-model="modalSignup"
        max-width="500"
        scrollable
        persistent
        >
        <v-card>
            <v-toolbar color="#f0e633">
            <v-toolbar-title>Nueva cuenta</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon @click="modalSignup = false">
                <v-icon>mdi-close</v-icon>
            </v-btn>
            </v-toolbar>
            <v-form
                ref="form"
                
            >
            <v-card-text>
                  <v-text-field
                    v-model="name"
                    :error-messages="nameErrors"
                    label="Nombre completo"
                    required
                    outlined
                    @input="$v.name.$touch()"
                    @blur="$v.name.$touch()"
                  ></v-text-field>

                  <v-text-field
                    v-model.trim="email"
                    :error-messages="emailErrors"
                    label="E-mail"
                    required
                    outlined
                    @input="$v.email.$touch()"
                    @blur="$v.email.$touch()"
                  ></v-text-field>

                  <v-text-field
                    v-model.trim="phone"
                    :error-messages="phoneErrors"
                    :counter="10"
                    label="Celular"
                    required
                    outlined
                    @input="$v.phone.$touch()"
                    @blur="$v.phone.$touch()"
                  ></v-text-field>

                  <v-text-field
                    :append-icon="show4 ? 'mdi-eye-off' : 'mdi-eye'"
                    v-model.trim="password"
                    :type="show4 ? 'text' : 'password'"
                    :error-messages="passwordErrors"
                    label="Contraseña"
                    required
                    outlined
                    @input="$v.password.$touch()"
                    @blur="$v.password.$touch()"
                    @click:append="show4 = !show4"
                  ></v-text-field>

                  <v-checkbox
                    v-model="checkbox"
                    :error-messages="checkboxErrors"
                    required
                    @change="$v.checkbox.$touch()"
                    @blur="$v.checkbox.$touch()"
                  >
                  <template v-slot:label>
                    <div>
                      He leído y acepto los 
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <a
                            target="_blank"
                            href="http://festyapp.back4app.io/terms_and_conditions.html"
                            @click.stop
                            v-on="on"
                          >
                            términos y condiciones del servicio
                          </a>
                        </template>
                        Clic para ver términos y condiciones
                      </v-tooltip>
                    </div>
                  </template>
                </v-checkbox>
                
            </v-card-text>
            </v-form>
            
            <v-divider ma-0 style="margin-left:1px;"></v-divider>
            <v-card-actions style="padding: 15px;">
                <v-row wrap>                    
                    <v-btn dark block large @click="signUpFesty()">Enviar</v-btn>
                </v-row>
            </v-card-actions>
            
        </v-card>
        </v-dialog>


      </v-container>

</template>

<script>
/* eslint-disable */
import Parse from 'parse'
import { validationMixin } from 'vuelidate'
//eslint-disable-next-line
import { required, minLength, email } from 'vuelidate/lib/validators'

const isPhone = (value) => /^3[0-3]\d{8}$/.test(value); 

  export default {
    mounted (){
      //console.log(Parse.User.current())
      this.$store.state.admin_view = true
      this.$store.state.isValueDeterminate = false
      if(Parse.User.current()){
         this.$router.push('/')
      }
    },

    mixins: [validationMixin],

    validations: {
      name: { required },
      email: { required, email },
      password: { required },
      checkbox: {
        required,
        checked (val) {
          return val
        }
      },
      phone: {
        required,
        phoneValid: isPhone
      },
    },

    data: () => ({
      drawer: null,
      user: {
        email: null,
        password: null
      },
      error: null,
      alert: true,
      success: null,
      alertSuccess: true,
      emailReset: '',
      resetModal: false,
      modalSignup: false,

      name: '',
      identification: '',
      email: '',
      password: '',
      phone: '',
      checkbox: false,
      show4: false
    }),

    props: {
      source: String
    },

    computed: {
      checkboxErrors () {
        const errors = []
        if (!this.$v.checkbox.$dirty) return errors
        !this.$v.checkbox.checked && errors.push('Debes aceptar los terminos y condiciones.')
        return errors
      },
      passwordErrors () {
        const errors = []
        if (!this.$v.password.$dirty) return errors
        !this.$v.password.required && errors.push('El password es requerido.')
        return errors
      },
      nameErrors () {
        const errors = []
        if (!this.$v.name.$dirty) return errors
        //!this.$v.name.minLength && errors.push('El nombre debe contener al menos 10 caracteres.')
        !this.$v.name.required && errors.push('Nombre es requerido.')
        return errors
      },
      emailErrors () {
        const errors = []
        if (!this.$v.email.$dirty) return errors
        !this.$v.email.email && errors.push('El email no es valido.')
        !this.$v.email.required && errors.push('E-mail es requerido.')
        return errors
      },
      phoneErrors () {
        const errors = []
        if (!this.$v.phone.$dirty) return errors
        !this.$v.phone.phoneValid && errors.push('El celular no es valido.')
        !this.$v.phone.required && errors.push('Celular es requerido.')
        return errors
      },
    },

    methods: {

      async login () {
        let self = this
        self.error = null

        try {

          if (!self.user.email || !self.user.password) {
            self.error = { message: "Los campos no deben estar vacios" };
            throw self.error;
          }

          let email_login = self.user.email.trim();
          email_login = email_login.toLowerCase();

          await Parse.User.logIn(email_login, self.user.password);
          const loggedIn = Parse.User.current();
          
          OneSignal.push(function() {
            OneSignal.setExternalUserId(loggedIn.id);
          });

          //self.$emit('loginSuccess', res)
          //const isAdmin = loggedIn.attributes.assistant;
          /*if(!isAdmin){
            Parse.User.logOut()
            //this.loggedUser = null
            throw self.error = { message: "No eres administrador." };
          }*/

          //this.$router.go('/')
          window.location.href = this.$router.history._startLocation;

        } catch (error) {
          this.alert = true
          this.error = error
          console.log(error.message)
        }
      },
      resetPassword(){

        if (!this.emailReset) {
            this.$snotify.error('Debe diligenciar correctamente el formulario.' , 'Formulario', {
                  timeout: 4000,
                  preventDuplicates: true
                });
            return;
        }
        Parse.User.requestPasswordReset(this.emailReset)
            .then(() => {

                this.$snotify.success('Correo de recuperación enviado correctamente.' , 'Enviado', {
                  timeout: 4000,
                  preventDuplicates: true
                });

                this.resetModal = false
            
            }).catch((error) => {
            // Show the error message somewhere
            this.$snotify.error(error.message , error.code, {
              timeout: 4000,
              preventDuplicates: true
            });
        });
      },

      async signUpFesty(){
        this.$v.$touch()
          if(!this.$v.$invalid) {
            let user = new Parse.User();
            user.set("name", this.name);
            user.set("password", this.password);
            user.set("email", this.email);
            user.set("username", this.email);
            user.set("phone", this.phone);
            user.set("os", 'web');

            try {
              await user.signUp();

              const loggedIn = Parse.User.current();
              OneSignal.push(function() {
                OneSignal.setExternalUserId(loggedIn.id);
              });
                        
              this.$snotify.success('Registro exitoso.' , 'Registro', {
                timeout: 4000,
                preventDuplicates: true
              });
                        
               //this.$router.push(this.$router.history._startLocation)
               window.localStorage.setItem("addressFestyApp", '')
               window.location.href = this.$router.history._startLocation;

            } catch (error) {
              this.$snotify.error(error.message , 'Registro', {
                timeout: 4000,
                preventDuplicates: true
              }); 
            }
          }else{
            this.$snotify.error('Formulario invalido.' , 'Registro', {
              timeout: 4000,
              preventDuplicates: true
            }); 
          }
      }
    }
  }
</script>